import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '../reducer.util';
import { createSagaReducer } from '../saga.util';
import { set } from 'lodash';

const name = 'order';

const initialState = {
    getOrderCount: reducerUtils.initial(),
    getOrderList: reducerUtils.initial(),
    fulfillmentResults: {
        success: [],
        fail: []
    },
    getAllOrderListAndFulfillment: reducerUtils.initial(),
    selectedOrdersToFulfillment: reducerUtils.initial(),
    getAllOrderPrepareShipping: reducerUtils.initial(),

    getAllOrderForCSV: reducerUtils.initial(),
    currentOrderList: [],

    deleteFulfillments: reducerUtils.initial(),
}

const slice = createSlice({
    name,
    initialState,
    reducers: {
        ...createSagaReducer('getOrderCount', 'getOrderCount'),
        ...createSagaReducer('getOrderList', 'getOrderList'),
        ...createSagaReducer('getAllOrderListAndFulfillment', 'getAllOrderListAndFulfillment'),
        ...createSagaReducer('selectedOrdersToFulfillment', 'selectedOrdersToFulfillment'),
        ...createSagaReducer('getAllOrderPrepareShipping', 'getAllOrderPrepareShipping'),
        ...createSagaReducer('getAllOrderForCSV', 'getAllOrderForCSV'),
        ...createSagaReducer('deleteFulfillments', 'deleteFulfillments'),
        setFulfillmentResults: (state, action) => {
            state.fulfillmentResults = action.payload;
        },
        setCurrentOrderList: (state, action) => {
            state.currentOrderList = action.payload;
        }
    }
})

export const ORDER = slice.name;
export const orderReducer = slice.reducer;
export const orderAction = slice.actions;
