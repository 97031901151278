import { postFulfillmentItem } from "@store/order/orderApi";


/**
 * 
 * @param {Array} arr - The array of items to be processed.
 * @param {number} chunkSize - The number of items to be processed in each chunk.
 * @returns {Promise<{successed: Array, failed: Array, resultIds: Array}>} - An object containing arrays of successful, failed, and result IDs.
 */
export const chunkRequest = async (arr, chunkSize) => {
    let successed = []; 
    let failed = [];
    let resultIds = [];

    function chunkArray(arr, size) {
        const chunkedArr = [];
        for (let i = 0; i < arr.length; i += size) {
            chunkedArr.push(arr.slice(i, i + size));
        }
        return chunkedArr;
    }

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const chunkedArr = chunkArray(arr, chunkSize);

    for (let chunk of chunkedArr) {
        // 각 청크에 대해 병렬로 요청을 보냄
        const promises = chunk.map((item) => {
            return postFulfillmentItem({
                orderId: item.orderId,
                items: item.items,
                tracking: {
                    "company": null,
                    "uid": null,
                    "url": null
                }
            }).then(response => {
                return { status: response.status, orderId: item.orderId, responseId: response.data._id }
            }).catch(error => ({ status: error.response ? error.response.status : 500, orderId: item.orderId }));
        });

        // 병렬로 요청을 보내고 결과를 기다림
        const results = await Promise.all(promises);

        // 결과를 successed와 failed 배열에 분류
        results.forEach(result => {
            if (result.status === 200) {
                successed.push(result.orderId);
                resultIds.push(result.responseId);
            } else {
                failed.push(result.orderId);
            }
        });

        // 2초간 대기
        await sleep(1000);
    }

    return { successed, failed, resultIds };
};